<template>
  <div class="run_banner">
    <!-- @/assets/img/common/b2@2x.png -->
    <img :src="src" alt="" />
    <div class="bg">
      <div class="run_box">
        <h1>{{ bannertext }}</h1>
        <div class="line"></div>
        <span class="text">价值共生</span>
        <el-divider direction="vertical"></el-divider>
        <span class="text">成就共享</span>
        <el-divider direction="vertical"></el-divider>
        <span class="text">未来共赢</span>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "banner",
  props: {
    bannertext: {
      type: String,
      required: true,
      default: "",
    },
    src: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>
<style lang="less" scoped>
.run_banner {
  position: relative;
  overflow: hidden;
  font-family: "OPPO_font";
  z-index: 89;

  img {
    width: 100%;
    position: relative;
    z-index: -1;
    display: block;
  }

  .bg {
    position: absolute;
    top: 45%;
    width: 100%;
    height: 150px;
    background-color: #000;
    opacity: 0.6;
  }

  .run_box {
    position: absolute;
    top: 2%;
    z-index: 1999;
    left: 37%;
    color: #fff;
    width: 382px;
    text-align: center;

    h1 {
      font-size: 50px;
      letter-spacing: 3px;
      font-weight: 600;
      // -webkit-text-stroke: 6px #333;
      text-shadow: 2px 2px #333;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #fff;
      margin: 0 auto;
      margin: 5px 0;
    }

    .text {
      font-size: 24px;
      padding: 10px;
      // -webkit-text-stroke: 2px #333;
      text-shadow: 2px 2px #333;
    }
  }
}
</style>
