<template>
  <div>
    <newHaderCommponent />
    <!-- 头部 -->
    <bannerComponent :bannertext="bannertext" :src="src" />
    <!-- 内容  -->
    <router-view />
    <!-- 底部 -->
    <homeFootComponent />
  </div>
</template>
<script>
import bannerComponent from "@/components/banner/index.vue";
import newHaderCommponent from "@/components/new_header/index.vue";
import homeFootComponent from "@/components/homeFoot/homeFoot.vue";
export default {
  name: "Layout",
  components: {
    homeFootComponent,
    bannerComponent,
    newHaderCommponent,
  },
  watch: {
    $route(to, from) {
      this.bannertext = to.meta.bannerText;
      this.src = to.meta.src;
    },
  },
  mounted() {
    this.bannertext = this.$route.meta.bannerText;
    this.src = this.$route.meta.src;
  },
  data() {
    return {
      bannertext: "",
      src: "",
    };
  },
};
</script>
