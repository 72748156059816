<template>
  <div class="positionFiex">
    <div class="header">
      <div>
        <div class="header_logo ">
          <img src="@/assets/img/logo/logo.png" style="height: 24px;cursor: pointer;"
            @click="handleClick({ href: '/' })" />
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-demo menu_header" mode="horizontal" @select="handleSelect"
          background-color="#B70003" text-color="#fff" active-text-color="#e1e1e1">
          <template v-for="(item, index) in navList">
            <el-menu-item :index="index + ''" v-if="!item.children" @click="handleClick(item)">{{ item.text
            }}</el-menu-item>
            <el-submenu :index="index + ''" v-else>
              <template slot="title" @click="handleClick(item)">{{ item.text }}</template>
              <template v-for="(e, i) in item.children">
                <el-menu-item :index="index + ' ' + i" v-if="!e.children" @click="handleClick(e)">{{ e.text
                }}</el-menu-item>
                <el-submenu :index="index + ' ' + i" v-else>
                  <template slot="title" @click="handleClick(e)">{{ e.text }}</template>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
import navList from '@/json/navList.json'
export default {
  data () {
    return {
      navList: navList,
      activeIndex: null
    };
  },
  watch: {
    '$route' (to, from) {
      this.activeIndex = localStorage.getItem('memuKey')
    }
  },
  methods: {
    handleSelect (key,) {
      localStorage.setItem('memuKey', key)
    },
    handleClick (value) {
      const { href } = value
      this.$router.push({
        path: href
      })
    }
  },
}
</script>
<style lang="less" scoped>
.positionFiex {
  position: relative;
  z-index: 999;

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 43px;
    background-color: #B70003;
    z-index: 500;


    >div {
      margin: 0 auto;
      max-width: 1280px;
      background-color: #B70003;
      height: 43px;
      padding: 0 0 0;


      .header_logo {
        display: inline-block;
        height: 43px;
        padding-top: 10px;
        padding-right: 67px;

        img> {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .menu_header {
        width: 80%;
        height: 43px;
        display: inline-block;
      }
    }

    /deep/ .el-menu-item {
      height: 43px;
      line-height: 43px;

    }

    /deep/ .el-submenu__title {
      height: 43px !important;
      line-height: 43px !important;
      overflow: hidden !important
    }

    /deep/ .el-menu--horizontal {
      border: none;
    }
  }
}
</style>