<template>
  <div>
    <div class="foot">
      <el-row>
        <el-col :span="20">
          <el-row>
            <div class="lis" v-for="(item, index) in footList" :key="index">
              <el-col :span="4">
                <a @click="clickTo(item)">{{ item.text }}</a>
                <a v-for="(e, i ) in item.children" :key="i" @click="clickTo(e)">{{ e.text }} </a>
              </el-col>
            </div>
          </el-row>
        </el-col>
        <el-col :span="4">
          <div class="foot_code" style="text-align:center;min-width: 160px">
            <div>
              <img src="@/assets/images/contact/er@2x.png" style="width: 160px;height: 160px;" />
            </div>
            <p style="color:#B3B3B3;margin-top: 10px;">扫码关注朔商集团</p>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="foot_bt">
      <div class="foot_bt_box clearfix">
        <div class="foot_bt_b">
          <p>
            Copy © 2022-2026 朔商集团有限公司 All Rights Reserved
            &nbsp; | &nbsp;
            <a target="_blank" href="https://beian.miit.gov.cn/">鄂ICP备2023002808号-1</a>
            <!-- &nbsp; | &nbsp; -->
            <!-- href="##"  href="https://beian.miit.gov.cn/"-->
            <!-- <a target="_blank" href="##">鄂公网安备XXXXXXXXX号</a> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navList from '@/json/navList.json'
export default {
  name: 'homeFoot',
  data () {
    return {
      footList: navList
    }
  },
  methods: {
    clickTo (value) {
      const { href, memuKey } = value
      localStorage.setItem('memuKey', memuKey)
      this.$router.push({
        path: href
      })
    },

  }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .foot {
    display: none;
  }
}

.foot {
  width: 100%;
  background: #17171A;
  padding: 60px 20%;
}

.lis {
  a {
    display: block;
    font-size: 14px;
    line-height: 30px;
    color: #E0E0E0;
    margin-bottom: 10px;
    cursor: pointer;

    //第一个a标签样式
    &:first-child {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

}

.foot_bt {
  width: 100%;
  background: #17171A;
  color: #B3B3B3;

  .foot_bt_box {
    max-width: 1280px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #343538;


    .foot_bt_b {
      font-size: 14px;
      color: #B3B3B3;

      a {
        color: #B3B3B3;
      }
    }
  }
}
</style>