<template>
  <div class="stpits-container">
    <div class="stpits-left">
      <div class="left-line">
        <img src="@/assets/images/introduction/sTit@2x.png">
      </div>
      <div class="left-title">
        <h1> {{ msg }}</h1>
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="stpits-right">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'stpits',
  props: {
    msg: {
      type: String,
      default: '',
      required: true
    },
    title: {
      type: String,
      default: '',
      required: true
    }
  },

}
</script>
<style lang="less" scoped>
.stpits-container {
  display: flex;
  margin: 2% auto;

  .stpits-left {
    flex: 1;

    .left-line {

      display: inline-block;
      width: 4px;
      height: 52px;
      margin-right: 11px;
      padding-top: 13px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .left-title {
      display: inline-block;
      vertical-align: top;

      h1 {
        color: #363636;
        font-size: 24px;
      }

      span {
        color: #ADADAD;
      }
    }
  }

  .stpits-right {
    flex: 1;
    text-align: right;
    line-height: 50px;

    i {
      font-size: 18px;
    }
  }
}
</style>