<template>
  <div>
    <newHaderCommponent />
    <router-view />
    <groupFootComponents />
    <homeFootComponent />
  </div>
</template>
<script>
import homeFootComponent from '@/components/homeFoot/homeFoot.vue';
import newHaderCommponent from '@/components/new_header/index.vue'
import groupFootComponents from '@/components/groupFoot/index.vue'
export default {
  components: {
    newHaderCommponent,
    groupFootComponents,
    homeFootComponent
  }

}

</script>
<style></style>