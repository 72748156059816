<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    articleList () {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        this.$wow.init()
      });
    },
  },

}
</script>