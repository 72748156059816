import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//样式引入
import './utils/imprtStyle'

import './permission' //路由权限

import { WOW } from 'wowjs'
import animated from 'wowjs/css/libs/animate.css'

import axios from 'axios'

//注册全局组件
import stpitComponent from '@/components/stpits/index.vue'

Vue.component('stpitComponent', stpitComponent)

//创建全局实例
Vue.prototype.$wow = new WOW({
	boxClass: 'wow', // 需要执行动画的元素的 class
	animateClass: 'animated', //animation.css 动画的 class
	offset: 0, // 距离可视区域多少开始执行动画
	mobile: true, // 是否在移动设备上执行动画
	live: true // 异步加载的内容是否有效
})

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	ak: 'SQHAy86ifs2OfxI4KqMOAixMgPY5xlLq'
})

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import jquery from 'jquery'
Vue.prototype.$ = jquery

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	if (to.fullPath == '/') {
		localStorage.setItem('memuKey', null)
	}
	next()
})

Vue.use(ElementUI)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
