import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import groupLayout from '@/views/group/groupLayout/groupLayout.vue'
import rshdLaayoutVue from '@/views/rshd/rshdLaayout/rshdLaayout.vue'
import sywhLayoutVue from '@/views/sywh/sywhLayout/sywhLayout.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/home/index.vue'),
		meta: {
			title: '首页'
		}
	},
	{
		path: '/home',
		component: Layout,
		redirect: 'speech',
		meta: {
			title: '董事长介绍'
		},
		children: [
			// {
			// 	path: 'speech',
			// 	name: 'speech',
			// 	component: () => import('@/views/speech/index.vue'),
			// 	meta: {
			// 		title: '董事长介绍',
			// 		bannerText: '董事长介绍'
			// 	}
			// },
			{
				path: 'news',
				name: 'news',
				component: () => import('@/views/news/index.vue'),
				meta: {
					title: '新闻中心',
					bannerText: '新闻中心',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807170401A006.jpg',
				}
			},
			{
				path: 'video',
				name: 'video',
				component: () => import('@/views/video/index.vue'),
				meta: {
					title: '视频中心',
					bannerText: '视频中心',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807170401A006.jpg',
				}
			},
			{
				path: 'human',
				name: 'human',
				component: () => import('@/views/human/index.vue'),
				meta: {
					title: '人才招聘',
					bannerText: '人才招聘',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807164255A004.jpg',
				}
			},
			{
				path: 'society',
				name: 'society',
				component: () => import('@/views/society/index.vue'),
				meta: {
					title: '社会责任',
					bannerText: '社会责任',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807170521A007.jpg',
				}
			},
			{
				path: 'introduction',
				name: 'introduction',
				component: () => import('@/views/introduction/index.vue'),
				meta: {
					title: '集团介绍',
					bannerText: '集团介绍',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807170038A005.jpg',
				}
			},
			// 企业文化
			{
				path: 'culture',
				name: 'culture',
				component: () => import('@/views/culture/index.vue'),
				meta: {
					title: '企业文化',
					bannerText: '企业文化',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807170038A005.jpg',
				}
			},
			//联系我们
			{
				path: 'contact',
				name: 'contact',
				component: () => import('@/views/contact/index.vue'),
				meta: {
					title: '联系朔商',
					bannerText:'联系朔商',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807171115A008.jpg',
				}
			},
			// 人才发展
			{
				path: 'talent',
				name: 'talent',
				component: () => import('@/views/talent/index.vue'),
				meta: {
					title: '人才发展',
					bannerText: '人才发展',
					src: 'https://solclub-prod.oss-cn-hangzhou.aliyuncs.com/video/20230807164255A004.jpg',
				}
			},
			//新闻详情
			{
				path: 'newInfo',
				name: 'newInfo',
				component: () => import('@/views/news/newInfo.vue'),
				meta: {
					title: '新闻详情',
					bannerText: '新闻详情'
				}
			},
			//视频详情
			{
				path: 'videoInfo',
				name: 'videoInfo',
				component: () => import('@/views/video/videoInfo.vue'),
				meta: {
					title: '视频中心',
					bannerText: '视频中心'
				}
			},
			// society
			{
				path: 'societyInfo',
				name: 'societyInfo',
				component: () => import('@/views/society/societyInfo.vue'),
				meta: {
					title: '社会责任详情',
					bannerText: '社会责任详情'
				}
			},
			// 集团简介
			{
				path: 'aboutInfo',
				name: 'aboutInfo',
				component: () => import('@/views/aboutInfo/aboutInfo.vue'),
				meta: {
					title: '集团简介',
					bannerText: '集团简介'
				}
			}
			// //发展战略
			// {
			// 	path: 'strategy',
			// 	name: 'strategy',
			// 	component: () => import('@/views/strategy/index.vue'),
			// 	meta: {
			// 		title: '发展战略',
			// 		bannerText: '发展战略'
			// 	}
			// }
		]
	},
	{
		path: '/group',
		name: 'group',
		redirect: 'yqj',
		component: groupLayout,
		meta: {
			title: '集团企业'
		},
		children: [
			{
				path: 'yqj',
				name: 'yqj',
				component: () => import('@/views/group/index.vue'),
				meta: {
					title: '盈乾家',
					bannerText: '盈乾家'
				}
			},
			{
				path: 'productInfo',
				name: 'productInfo',
				component: () => import('@/views/group/dataInfo.vue'),
				meta: {
					title: '产品详情',
					bannerText: '产品详情'
				}
			}
		]
	},
	{
		path: '/rshd',
		name: 'rshd',
		redirect: 'rshdHome',
		component: rshdLaayoutVue,
		meta: {
			title: '榕生慧地'
		},
		children: [
			{
				path: 'rshdHome',
				name: 'rshdHome',
				component: () => import('@/views/rshd/index.vue'),
				meta: {
					title: '榕生慧地',
					bannerText: '榕生慧地'
				}
			},
			{
				path: 'dwelling',
				name: 'dwelling',
				component: () => import('@/views/rshd/components/dwelling.vue'),
				meta: {
					title: '住宅项目',
					bannerText: '住宅项目'
				}
			},
			{
				path: 'productall',
				name: 'productall',
				component: () => import('@/views/rshd/components/productAll.vue'),
				meta: {
					title: '地产开发',
					bannerText: '地产开发'
				}
			},
			{
				path: 'estate',
				name: 'estate',
				component: () => import('@/views/rshd/components/estate.vue'),
				meta: {
					title: '商业资讯',
					bannerText: '商业资讯'
				}
			}
		]
	},
	{
		// sywhLayoutVue
		path: '/sywh',
		name: 'sywh',
		redirect: 'sywhHome',
		component: rshdLaayoutVue,
		meta: {
			title: '溯源文化'
		},
		children: [
			{
				path: 'sywhHome',
				name: 'sywhHome',
				component: () => import('@/views/sywh/index.vue'),
				meta: {
					title: '溯元文化',
					bannerText: '溯元文化'

				}
			}
		]
	},
	{
		// sywhLayoutVue
		path: '/home',
		redirect: 'speech',
		component: rshdLaayoutVue,
		meta: {
			title: '董事长介绍'
		},
		children: [
			{
				path: 'speech',
				name: 'speech',
				component: () => import('@/views/speech/index.vue'),
				meta: {
					title: '董事长介绍',
					bannerText: '董事长介绍'
				}
			}
		]
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
