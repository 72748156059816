import router, { resetRouter } from './router'
import getPageTitle from '@/utils/page-title'
import { asyncRoutes, constantRoutes } from '@/router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // npro

router.beforeEach(async (to, from, next) => {
	// 路由跳转后，让页面回到顶部
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageYOffset = 0
	//设置页面title
	NProgress.start()
	document.title = getPageTitle(to.meta.title)
	next()
})

//当路由跳转结束后
router.afterEach(() => {
	// 关闭进度条
	NProgress.done()
})
